import plugin_vue3_A0OWXRrUgq from "/var/www/www.coleccionmilenioarte.com/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/var/www/www.coleccionmilenioarte.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/www.coleccionmilenioarte.com/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/var/www/www.coleccionmilenioarte.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/www.coleccionmilenioarte.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/var/www/www.coleccionmilenioarte.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/var/www/www.coleccionmilenioarte.com/.nuxt/unocss.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/www.coleccionmilenioarte.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_B4ptqVdIfe from "/var/www/www.coleccionmilenioarte.com/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import assets_qXGiJnymQr from "/var/www/www.coleccionmilenioarte.com/src/plugins/assets.ts";
import facebookAuth_hQJ70S68df from "/var/www/www.coleccionmilenioarte.com/src/plugins/facebookAuth.ts";
import googleAuth_W0FPdjtRDd from "/var/www/www.coleccionmilenioarte.com/src/plugins/googleAuth.ts";
import scrollToTop_qK8NwO4Kxq from "/var/www/www.coleccionmilenioarte.com/src/plugins/scrollToTop.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  unocss_MzCDxu9LMj,
  chunk_reload_client_UciE0i6zes,
  ssr_plugin_B4ptqVdIfe,
  assets_qXGiJnymQr,
  facebookAuth_hQJ70S68df,
  googleAuth_W0FPdjtRDd,
  scrollToTop_qK8NwO4Kxq
]