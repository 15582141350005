import { default as _91slug_93VbplqHyWFRMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/artistas/[slug].vue?macro=true";
import { default as index0kMpYpsHQmMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/artistas/index.vue?macro=true";
import { default as aviso_45de_45privacidadc2494yFK4aMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/aviso-de-privacidad.vue?macro=true";
import { default as indexgXlZ872eJ5Meta } from "/var/www/www.coleccionmilenioarte.com/src/pages/exhibiciones/index.vue?macro=true";
import { default as google_45auth_45callback1I5BDk8USpMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/google-auth-callback.vue?macro=true";
import { default as indexkPr2xEFlCqMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/index.vue?macro=true";
import { default as loginq4nwwYZ9bMMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/login.vue?macro=true";
import { default as nosotrosnskH5g07hMMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/nosotros.vue?macro=true";
import { default as indexeneYrbWoHzMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/perfil/index.vue?macro=true";
import { default as index2c3vGuGtSlMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/proyectos-especiales/index.vue?macro=true";
import { default as recuperars0e6U9Sf2qMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/recuperar.vue?macro=true";
import { default as registro6vjwRp6SIZMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/registro.vue?macro=true";
import { default as restablecerRI1K27vvFOMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/restablecer.vue?macro=true";
import { default as searchkAFcA1hSmnMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/search.vue?macro=true";
import { default as indexX9oRBe7V19Meta } from "/var/www/www.coleccionmilenioarte.com/src/pages/series/index.vue?macro=true";
import { default as verificacionQTPwUdlMMuMeta } from "/var/www/www.coleccionmilenioarte.com/src/pages/verificacion.vue?macro=true";
import { default as PostDetailsHf9SqIvMR0Meta } from "~/components/views/PostDetails.vue?macro=true";
export default [
  {
    name: _91slug_93VbplqHyWFRMeta?.name ?? "artistas-slug",
    path: _91slug_93VbplqHyWFRMeta?.path ?? "/artistas/:slug()",
    meta: _91slug_93VbplqHyWFRMeta || {},
    alias: _91slug_93VbplqHyWFRMeta?.alias || [],
    redirect: _91slug_93VbplqHyWFRMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/artistas/[slug].vue").then(m => m.default || m)
  },
  {
    name: index0kMpYpsHQmMeta?.name ?? "artistas",
    path: index0kMpYpsHQmMeta?.path ?? "/artistas",
    meta: index0kMpYpsHQmMeta || {},
    alias: index0kMpYpsHQmMeta?.alias || [],
    redirect: index0kMpYpsHQmMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/artistas/index.vue").then(m => m.default || m)
  },
  {
    name: aviso_45de_45privacidadc2494yFK4aMeta?.name ?? "aviso-de-privacidad",
    path: aviso_45de_45privacidadc2494yFK4aMeta?.path ?? "/aviso-de-privacidad",
    meta: aviso_45de_45privacidadc2494yFK4aMeta || {},
    alias: aviso_45de_45privacidadc2494yFK4aMeta?.alias || [],
    redirect: aviso_45de_45privacidadc2494yFK4aMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/aviso-de-privacidad.vue").then(m => m.default || m)
  },
  {
    name: indexgXlZ872eJ5Meta?.name ?? "exhibiciones",
    path: indexgXlZ872eJ5Meta?.path ?? "/exhibiciones",
    meta: indexgXlZ872eJ5Meta || {},
    alias: indexgXlZ872eJ5Meta?.alias || [],
    redirect: indexgXlZ872eJ5Meta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/exhibiciones/index.vue").then(m => m.default || m)
  },
  {
    name: google_45auth_45callback1I5BDk8USpMeta?.name ?? "google-auth-callback",
    path: google_45auth_45callback1I5BDk8USpMeta?.path ?? "/google-auth-callback",
    meta: google_45auth_45callback1I5BDk8USpMeta || {},
    alias: google_45auth_45callback1I5BDk8USpMeta?.alias || [],
    redirect: google_45auth_45callback1I5BDk8USpMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/google-auth-callback.vue").then(m => m.default || m)
  },
  {
    name: indexkPr2xEFlCqMeta?.name ?? "index",
    path: indexkPr2xEFlCqMeta?.path ?? "/",
    meta: indexkPr2xEFlCqMeta || {},
    alias: indexkPr2xEFlCqMeta?.alias || [],
    redirect: indexkPr2xEFlCqMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginq4nwwYZ9bMMeta?.name ?? "login",
    path: loginq4nwwYZ9bMMeta?.path ?? "/login",
    meta: loginq4nwwYZ9bMMeta || {},
    alias: loginq4nwwYZ9bMMeta?.alias || [],
    redirect: loginq4nwwYZ9bMMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: nosotrosnskH5g07hMMeta?.name ?? "nosotros",
    path: nosotrosnskH5g07hMMeta?.path ?? "/nosotros",
    meta: nosotrosnskH5g07hMMeta || {},
    alias: nosotrosnskH5g07hMMeta?.alias || [],
    redirect: nosotrosnskH5g07hMMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/nosotros.vue").then(m => m.default || m)
  },
  {
    name: indexeneYrbWoHzMeta?.name ?? "perfil",
    path: indexeneYrbWoHzMeta?.path ?? "/perfil",
    meta: indexeneYrbWoHzMeta || {},
    alias: indexeneYrbWoHzMeta?.alias || [],
    redirect: indexeneYrbWoHzMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: index2c3vGuGtSlMeta?.name ?? "proyectos-especiales",
    path: index2c3vGuGtSlMeta?.path ?? "/proyectos-especiales",
    meta: index2c3vGuGtSlMeta || {},
    alias: index2c3vGuGtSlMeta?.alias || [],
    redirect: index2c3vGuGtSlMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/proyectos-especiales/index.vue").then(m => m.default || m)
  },
  {
    name: recuperars0e6U9Sf2qMeta?.name ?? "recuperar",
    path: recuperars0e6U9Sf2qMeta?.path ?? "/recuperar",
    meta: recuperars0e6U9Sf2qMeta || {},
    alias: recuperars0e6U9Sf2qMeta?.alias || [],
    redirect: recuperars0e6U9Sf2qMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/recuperar.vue").then(m => m.default || m)
  },
  {
    name: registro6vjwRp6SIZMeta?.name ?? "registro",
    path: registro6vjwRp6SIZMeta?.path ?? "/registro",
    meta: registro6vjwRp6SIZMeta || {},
    alias: registro6vjwRp6SIZMeta?.alias || [],
    redirect: registro6vjwRp6SIZMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/registro.vue").then(m => m.default || m)
  },
  {
    name: restablecerRI1K27vvFOMeta?.name ?? "restablecer",
    path: restablecerRI1K27vvFOMeta?.path ?? "/restablecer",
    meta: restablecerRI1K27vvFOMeta || {},
    alias: restablecerRI1K27vvFOMeta?.alias || [],
    redirect: restablecerRI1K27vvFOMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/restablecer.vue").then(m => m.default || m)
  },
  {
    name: searchkAFcA1hSmnMeta?.name ?? "search",
    path: searchkAFcA1hSmnMeta?.path ?? "/search",
    meta: searchkAFcA1hSmnMeta || {},
    alias: searchkAFcA1hSmnMeta?.alias || [],
    redirect: searchkAFcA1hSmnMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: indexX9oRBe7V19Meta?.name ?? "series",
    path: indexX9oRBe7V19Meta?.path ?? "/series",
    meta: indexX9oRBe7V19Meta || {},
    alias: indexX9oRBe7V19Meta?.alias || [],
    redirect: indexX9oRBe7V19Meta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/series/index.vue").then(m => m.default || m)
  },
  {
    name: verificacionQTPwUdlMMuMeta?.name ?? "verificacion",
    path: verificacionQTPwUdlMMuMeta?.path ?? "/verificacion",
    meta: verificacionQTPwUdlMMuMeta || {},
    alias: verificacionQTPwUdlMMuMeta?.alias || [],
    redirect: verificacionQTPwUdlMMuMeta?.redirect || undefined,
    component: () => import("/var/www/www.coleccionmilenioarte.com/src/pages/verificacion.vue").then(m => m.default || m)
  },
  {
    name: PostDetailsHf9SqIvMR0Meta?.name ?? "series-slug",
    path: PostDetailsHf9SqIvMR0Meta?.path ?? "/series/:slug",
    meta: PostDetailsHf9SqIvMR0Meta || {},
    alias: PostDetailsHf9SqIvMR0Meta?.alias || [],
    redirect: PostDetailsHf9SqIvMR0Meta?.redirect || undefined,
    component: () => import("~/components/views/PostDetails.vue").then(m => m.default || m)
  },
  {
    name: PostDetailsHf9SqIvMR0Meta?.name ?? "exhibiciones-slug",
    path: PostDetailsHf9SqIvMR0Meta?.path ?? "/exhibiciones/:slug",
    meta: PostDetailsHf9SqIvMR0Meta || {},
    alias: PostDetailsHf9SqIvMR0Meta?.alias || [],
    redirect: PostDetailsHf9SqIvMR0Meta?.redirect || undefined,
    component: () => import("~/components/views/PostDetails.vue").then(m => m.default || m)
  },
  {
    name: PostDetailsHf9SqIvMR0Meta?.name ?? "proyectos-especiales-slug",
    path: PostDetailsHf9SqIvMR0Meta?.path ?? "/proyectos-especiales/:slug",
    meta: PostDetailsHf9SqIvMR0Meta || {},
    alias: PostDetailsHf9SqIvMR0Meta?.alias || [],
    redirect: PostDetailsHf9SqIvMR0Meta?.redirect || undefined,
    component: () => import("~/components/views/PostDetails.vue").then(m => m.default || m)
  }
]